import { useTheme } from '@mui/material/styles';

export const Logo = () => {
  const theme = useTheme();
  const fillColor = theme.palette.primary.main;

  return (
    <svg
      fill='white'
      height="100%"
      viewBox="0 0 230 60"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
	<g>
		<g>
			<g>
				<g>
					<path class="st0" d="M48.4,7.2l-5.6,3.3l-5.6-3.3c-1-0.6-2.3,0.1-2.3,1.3v4.1v0.7v4.1c0,1.2,1.3,1.9,2.3,1.3l5.6-3.3l5.6,3.3
						c1,0.6,2.3-0.1,2.3-1.3v-4.1v-0.7V8.5C50.7,7.3,49.4,6.6,48.4,7.2z"/>
				</g>
			</g>
		</g>
		<path class="st0" d="M44.8,53l6-28.9h6.2c1.3,0,2.3-1,2.3-2.3v-5.4c0-0.6,0.8-0.9,1.2-0.4l4.6,6c0.4,0.6,0.4,1.3,0,1.9l-4.2,6.1
			l1.7,2.7c0.4,0.6,0.3,1.4-0.1,1.9L46.8,53.9C46.1,54.8,44.6,54.1,44.8,53z"/>
		<path class="st0" d="M40.6,53l-6-28.9h-6.2c-1.3,0-2.3-1-2.3-2.3v-5.4c0-0.6-0.8-0.9-1.2-0.4l-4.6,6c-0.4,0.6-0.4,1.3,0,1.9
			l4.2,6.1l-1.7,2.7c-0.4,0.6-0.3,1.4,0.1,1.9l15.7,19.3C39.4,54.8,40.8,54.1,40.6,53z"/>
	</g>
	<g>
		<path class="st0" d="M91.4,50.4c-6.8,0-10.8-4.6-10.8-11.2V28.8h-5.4v-6.7h5.4v-6.4l7.1-1.8v8.2h9.6v6.7h-9.6v9.9
			c0,3.7,1.9,5.2,5.1,5.2c1.5,0,3.1-0.3,4.5-0.8v6.3C95.3,50.1,93.4,50.4,91.4,50.4z"/>
		<path class="st0" d="M124.2,49.7l-0.9-3.4c-2.4,2.6-5.7,4.1-9.6,4.1c-7.8,0-13.3-6-13.3-14.5s5.6-14.5,13.3-14.5
			c3.8,0,7.1,1.4,9.6,4.1l0.9-3.4h6.2v27.5H124.2z M115.4,28c-4.5,0-7.9,3.5-7.9,8c0,4.5,3.4,8,7.9,8c4.5,0,7.9-3.5,7.9-8
			C123.3,31.4,120,28,115.4,28z"/>
		<path class="st0" d="M140.2,9.3c2.4,0,4.1,1.7,4.1,4.1c0,2.4-1.8,4.1-4.1,4.1c-2.4,0-4.1-1.7-4.1-4.1
			C136.1,11,137.9,9.3,140.2,9.3z M136.7,22.2h7.1v27.5h-7.1V22.2z"/>
		<path class="st0" d="M150,11.2h7.1v38.5H150V11.2z"/>
		<path class="st0" d="M176.2,21.5c8.4,0,14.8,6.1,14.8,14.5s-6.5,14.5-14.8,14.5s-14.8-6.1-14.8-14.5S167.8,21.5,176.2,21.5z
			 M176.2,28.1c-4.5,0-7.7,3.5-7.7,7.8s3.2,7.8,7.7,7.8s7.7-3.5,7.7-7.8S180.7,28.1,176.2,28.1z"/>
		<path class="st0" d="M195.2,22.2h6.2l0.9,4.2h0.1c0.7-2.3,3.2-4.9,7.6-4.9h1.3v7.3c-0.7-0.3-1.8-0.4-2.7-0.4
			c-3.3,0-6.3,2.4-6.3,6.5v14.8h-7.1V22.2z"/>
	</g>
</g>
</svg>

  );
};
