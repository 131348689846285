import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

const STORAGE_KEY = 'app.settings';

const restoreSettings = () => {
  let value = null;

  try {
    const restored = window.localStorage.getItem(STORAGE_KEY);

    if (restored) {
      value = JSON.parse(restored);
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return value;
};

const deleteSettings = () => {
  try {
    window.localStorage.removeItem(STORAGE_KEY);
  } catch (err) {
    console.error(err);
  }
};

const storeSettings = (value) => {
  try {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
  } catch (err) {
    console.error(err);
  }
};

const initialSettings = {
  colorPreset: 'yellow',
  contrast: 'normal',
  direction: 'ltr',
  layout: 'vertical',
  navColor: 'evident',
  paletteMode: 'light',
  responsiveFontSizes: true,
  stretch: false
};

const initialState = {
  ...initialSettings,
  isInitialized: false,
  openDrawer: false
};
let initialSelectedProject = null;

export const SettingsContext = createContext({
  ...initialState,
  selectedProject: initialSelectedProject,
  handleDrawerClose: () => { },
  handleDrawerOpen: () => { },
  handleReset: () => { },
  handleUpdate: () => { },
  setSelectedProject: () => { },
  isCustom: false,
  updateSettings: () => { }
});

export const SettingsProvider = (props) => {
  const { children } = props;
  const [state, setState] = useState(initialState);
  const [selectedProject, setSelectedProject] = useState(initialSelectedProject);

  useEffect(() => {
    const restored = restoreSettings();
    console.log('Restored settings:', restored);

    if (restored) {
      setState((prevState) => ({
        ...prevState,
        ...restored,
        isInitialized: true
      }));
      setSelectedProject(restored.selectedProject);
    }
  }, []);

  const handleReset = useCallback(() => {
    deleteSettings();
    setState((prevState) => ({
      ...prevState,
      ...initialSettings
    }));
    setSelectedProject(null);
  }, []);

  const handleUpdate = useCallback((settings) => {
    console.log('handleUpdate called with settings:', settings);  // Debug log
    setState((prevState) => {
      const updatedSettings = {
        ...prevState,
        ...settings,
      };
  
      storeSettings(updatedSettings);
  
      return updatedSettings;
    });
  }, []);

  const handleDrawerOpen = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      openDrawer: true
    }));
  }, []);

  const handleDrawerClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      openDrawer: false
    }));
  }, []);

  const isCustom = useMemo(() => {
    return !isEqual(initialSettings, {
      colorPreset: state.colorPreset,
      contrast: state.contrast,
      direction: state.direction,
      layout: state.layout,
      navColor: state.navColor,
      paletteMode: state.paletteMode,
      responsiveFontSizes: state.responsiveFontSizes,
      stretch: state.stretch
    });
  }, [state]);

  const updateSettings = useCallback((newSettings) => {
    handleUpdate(newSettings);
  }, [handleUpdate]);

  return (
    <SettingsContext.Provider
      value={{
        ...state,
        selectedProject,
        setSelectedProject,
        handleDrawerClose,
        handleDrawerOpen,
        handleReset,
        handleUpdate,
        isCustom,
        updateSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const SettingsConsumer = SettingsContext.Consumer;
