import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const CampaignIDContext = createContext();

export const CampaignIDProvider = (props) => {
  const [campaignID, setcampaignID] = useState(null);

  return (
    <CampaignIDContext.Provider value={{ campaignID, setcampaignID }}>
      {props.children}
    </CampaignIDContext.Provider>
  );
};

CampaignIDProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
