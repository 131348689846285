import { eventData } from "src/api/chat/data1";
import { useAuth } from "./use-auth";

export const useMockedUser = () => {
  // To get the user from the authContext, you can use
 
  const { user } = useAuth();  
  return {
    id: user.id,
    userId: user.id,
    avatar: user.avatar,
    name: user.name,
    email: user.email,
    eventData: user.eventData,
    pageInfo: user.pageInfo,
    deliverable: user.deliverables
  };
};
