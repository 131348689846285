// api.js

import axios from 'axios';

export const getStatsData = async (campaignID, token) => {
  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://api.tailor.video/api/v1/analytics/event-data/${campaignID}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
