import { useCallback, useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { auth0Config } from 'src/config';
import { paths } from 'src/paths';
import { Issuer } from 'src/utils/auth';
import { AuthContext, initialState } from './auth-context';
import { CampaignIDContext } from '../campaignID';
import { setUserData } from '../userData';
import axios from 'axios';
import { getStatsData } from 'src/api/api';

console.log('auth0 base url',auth0Config.issuer_base_url)
console.log('redirect_url: ', auth0Config.base_url)

const auth0Client = new Auth0Client({
  domain: auth0Config.issuer_base_url,
  clientId: auth0Config.client_id,
  cacheLocation: 'localstorage',
  audience: auth0Config.audience,
  authorizationParams: {
    redirect_uri: auth0Config.base_url + paths.auth.auth0.callback,
    scope: "openid profile email offline_access",
  },
});

var ActionType;
(function (ActionType) {
  ActionType['INITIALIZE'] = 'INITIALIZE';
  ActionType['LOGIN'] = 'LOGIN';
  ActionType['LOGOUT'] = 'LOGOUT';
})(ActionType || (ActionType = {}));

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { campaignID } = useContext(CampaignIDContext);

  const initialize = useCallback(async () => {
    try {
      await auth0Client.checkSession();
      const isAuthenticated = await auth0Client.isAuthenticated();

      if (isAuthenticated) {
        const user = await auth0Client.getUser();
        const token = await auth0Client.getTokenSilently({
          authorizationParams: {
            audience: 'https://api.tailor.video/api/v1',
            scope: "openid profile email offline_access",
          },
        });

        // console.log('token', token);

        const campaignsRequest = await axios.get('https://api.tailor.video/api/v1/analytics/campaigns', { headers: { 'Authorization': `Bearer ${token}` } });

        const campaigns = campaignsRequest.data;

        console.log('campaigns response', campaignsRequest)

        // Added conditional check for campaignID here
        let statsDataAPICall = {
          eventData: [],
          pageInfo: []

        };
        if (campaignID) {
          statsDataAPICall = await getStatsData(campaignID, token);
        }

        const userObject = {
          id: user.sub,
          avatar: user.picture,
          email: user.email,
          name: user.name,
          plan: 'Premium',
          campaignID: campaignID,
          eventData: statsDataAPICall ? statsDataAPICall.eventData : null,
          pageInfo: statsDataAPICall ? statsDataAPICall.pageInfo : null,
          campaigns: campaigns,
          token: token
        };

        setUserData(userObject);

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated,
            user: userObject,
            token
          },
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [dispatch, campaignID]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const loginWithRedirect = useCallback(async (appState) => {
    await auth0Client.loginWithRedirect({
      appState,
    });
  }, []);

  const handleRedirectCallback = useCallback(async () => {
    const result = await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();

    dispatch({
      type: ActionType.LOGIN,
      payload: {
        user: {
          id: user.sub,
          avatar: user.picture,
          email: user.email,
          name: user.name,
          plan: 'Premium',
        },
      },
    });

    return result.appState;
  }, []);

  const logout = useCallback(async () => {
    await auth0Client.logout();
    dispatch({
      type: ActionType.LOGOUT,
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.Auth0,
        loginWithRedirect,
        handleRedirectCallback,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};