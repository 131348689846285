import { useContext } from 'react';
import { CampaignIDContext } from './campaignID';

export const useCampaignID = () => {
  const context = useContext(CampaignIDContext);

  if (!context) {
    throw new Error('useCampaignID must be used within a CampaignIDProvider');
  }

  return context;
};