let userData = null;
let loading = true;

export function setUserData(result) {
  userData = result;
  loading = false;
  console.log('userData', userData);
}

export function getUserData() {
  return { userData, loading };
}
