export const eventData = [
  {
    "_id": "6435bba43d4022329943c0ec",
    "eventName": "Play",
    "pagePath": "/test-embed",
    "campaignID": "364057669",
    "eventCount": 11
},
{
    "_id": "6435bba43d4022329943c163",
    "eventName": "page_view",
    "pagePath": "/test-embed",
    "campaignID": "364057669",
    "eventCount": 13
},
{
    "_id": "6435bba43d4022329943c1fe",
    "eventName": "Load",
    "pagePath": "/test-embed",
    "campaignID": "364057669",
    "eventCount": 9
},
{
    "_id": "6435bba53d4022329943c294",
    "eventName": "10% Viewed",
    "pagePath": "/test-embed",
    "campaignID": "364057669",
    "eventCount": 7
},
{
    "_id": "6435bba53d4022329943c36d",
    "eventName": "20% Viewed",
    "pagePath": "/test-embed",
    "campaignID": "364057669",
    "eventCount": 7
},
{
    "_id": "6435bba53d4022329943c41b",
    "eventName": "30% Viewed",
    "pagePath": "/test-embed",
    "campaignID": "364057669",
    "eventCount": 6
},
{
    "_id": "6435bba53d4022329943c530",
    "eventName": "40% Viewed",
    "pagePath": "/test-embed",
    "campaignID": "364057669",
    "eventCount": 6
},
{
    "_id": "6435bba63d4022329943c5ce",
    "eventName": "50% Viewed",
    "pagePath": "/test-embed",
    "campaignID": "364057669",
    "eventCount": 6
},
{
    "_id": "6435bba63d4022329943c771",
    "eventName": "60% Viewed",
    "pagePath": "/test-embed",
    "campaignID": "364057669",
    "eventCount": 6
},
{
    "_id": "6435bba73d4022329943c837",
    "eventName": "70% Viewed",
    "pagePath": "/test-embed",
    "campaignID": "364057669",
    "eventCount": 6
},
{
    "_id": "6435bba73d4022329943c940",
    "eventName": "80% Viewed",
    "pagePath": "/test-embed",
    "campaignID": "364057669",
    "eventCount": 4
},
{
    "_id": "6435bba73d4022329943c9e9",
    "eventName": "90% Viewed",
    "pagePath": "/test-embed",
    "campaignID": "364057669",
    "eventCount": 4
},
{
    "_id": "6435bba83d4022329943cab5",
    "eventName": "100% Viewed",
    "pagePath": "/test-embed",
    "campaignID": "364057669",
    "eventCount": 4
}
];

export const pageinfo = [
  {
    "_id": "6435bba93d4022329943cd2b",
    "infoType": "totalPageViews",
    "campaignID": "364057669",
    "value": 13
},
{
    "_id": "6435bba93d4022329943cd3c",
    "infoType": "uniquePageViews",
    "campaignID": "364057669",
    "value": 2
},
{
    "_id": "6435bba93d4022329943cd4a",
    "infoType": "pageViewsByPlatform",
    "platform": "desktop",
    "campaignID": "364057669",
    "value": 1
},
{
    "_id": "6435bba93d4022329943cd64",
    "infoType": "pageViewsByPlatform",
    "platform": "mobile",
    "campaignID": "364057669",
    "value": 1
},
{
    "_id": "6435bba93d4022329943cd73",
    "country": "United States",
    "infoType": "pageViewsByCountry",
    "campaignID": "364057669",
    "value": 2
},
{
    "_id": "6435bba93d4022329943cd77",
    "country": "United Kingdom",
    "infoType": "pageViewsByCountry",
    "campaignID": "364057669",
    "value": 6
},
{
    "_id": "6435bba93d4022329943cd34",
    "country": "Spain",
    "infoType": "pageViewsByCountry",
    "campaignID": "364057669",
    "value": 4
}
]
