import PropTypes from 'prop-types';
import React from 'react';
import { Box, Stack, SvgIcon, Typography, Avatar, Popover, MenuList, MenuItem } from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { TenantPopover } from './tenant-popover';
import { getcampaignID } from 'src/contexts/auth/campaignID';
import { getProjectName } from 'src/contexts/auth/projectName';
import { getUserData } from 'src/contexts/auth/userData';
import { useState, useEffect, useContext } from 'react';
import { SettingsContext } from 'src/contexts/settings-context';
import { CampaignIDContext } from 'src/contexts/auth/campaignID.js';
import { CaretUpDown as CaretUpDownIcon } from '@phosphor-icons/react/dist/ssr/CaretUpDown';

export const TenantSwitch = (props) => {
  const { settings, handleUpdate } = useContext(SettingsContext);
  const [campaigns, setcampaigns] = useState([]);
  const [localSelectedProject, setLocalSelectedProject] = useState(null);
  const { campaignID, setcampaignID } = useContext(CampaignIDContext);
  const popover = usePopover();

  function getFirstLetters(inputString) {
    // Split the input string into words using space as the delimiter
    const words = inputString.split(" ");

    // Check the number of words to determine what to return
    if (words.length >= 2) {
        // Return the first letter of the first two words if there are at least two words
        return words[0][0] + words[1][0];
    } else if (words.length === 1 && words[0] !== "") {
        // Return the first letter of the first word if there's only one word and it's not empty
        return words[0][0];
    } else {
        // Handle the case where the input string might be empty or just spaces
        return "Input string does not contain any words.";
    }
}

let avatarLabel = "N/A";
if (localSelectedProject && localSelectedProject.label) {
  avatarLabel = getFirstLetters(localSelectedProject.label);
}

  useEffect(() => {
    const data = getUserData();
    if (data.userData && Array.isArray(data.userData.campaigns)) {
      const campaignsData = data.userData.campaigns.map((item) => ({
        label: item.name,
        value: item.campaignID,
      }));
      setcampaigns(campaignsData);
      if (campaignsData.length > 0 && !campaignID) {
        setcampaignID(campaignsData[0].value);
      }
    }
  }, []);

  useEffect(() => {
    const selectedProject = settings && settings.project
      ? campaigns.find(project => project.value === settings.project)
      : campaigns[0];
    setLocalSelectedProject(selectedProject);
    if (selectedProject) {
      setcampaignID(selectedProject.value);
    }
  }, [campaigns, settings]);

  const handleSelectChange = (value) => {
    const selected = campaigns.find(project => project.value === value);
    setLocalSelectedProject(selected);
    handleUpdate({ project: value, selectedProject: selected });
    setcampaignID(selected.value);
    popover.handleClose();
  };

  return (
    <React.Fragment>
      <Stack
        direction="row"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        spacing={2}
        sx={{
          alignItems: 'center',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          borderRadius: '12px',
          cursor: 'pointer',
          p: '4px 8px',
        }}
      >
        <Avatar sx={{ bgcolor: '#ec6137' }} variant='rounded'>
        {avatarLabel}
        </Avatar>
        <Box sx={{ flex: '1 1 auto' }}>
          <Typography color="rgba(255, 255, 255, 0.4)" variant="caption">
            Campaign
          </Typography>
          <Typography color="white" variant="subtitle2">
            {localSelectedProject?.label || "Select"}
          </Typography>
        </Box>
        <CaretUpDownIcon color="white" fontSize="var(--icon-fontSize-sm)" />
      </Stack>
      <Popover
        anchorEl={popover.anchorRef.current}
        open={popover.open}
        onClose={popover.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList>
          {campaigns.map((campaign) => (
            <MenuItem
              key={campaign.value}
              selected={campaign.value === localSelectedProject?.value}
              onClick={() => handleSelectChange(campaign.value)}
            >
              {campaign.label}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </React.Fragment>
  );
};